<template>
  <div class="purchase-manage">
      <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'purchaseManage',
  data () {
    return {}
  }
}
</script>
<style lang="less" scoped>
.purchase-manage {
  width: 100%;
  height: 100%;
}
</style>

